import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Question = styled.div`
  flex: 1 0 50%;
  margin: 0 40px 0 0;
  max-width: calc(50% - 20px);
  &:nth-child(2n) {
    margin: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 1 0 100%;
    max-width: 100%;
  }
  h3 {
    font-size: 20px;
    color: ${props => props.theme.colors.fontDark};
    font-weight: 700;
    margin: 0 0 10px 0;
  }
  p {
    color: ${props => props.theme.colors.fontDark};
    margin: 10px 0 0 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    &:first-child {
      margin: 0;
    }
  }
`

const Answer = styled.div`
  padding: 0 0 40px 0;
`

class FaqQuestion extends React.PureComponent {
  static propTypes = {
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
  }

  render() {
    const { question, answer } = this.props
    return (
      <Question itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
        <h3 itemProp="name">{question}</h3>
        <Answer itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text" dangerouslySetInnerHTML={{ __html: answer }}/>
        </Answer>
      </Question>
    )
  }
}

export default FaqQuestion
