import React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { LocaleContext } from './Layout'
import Wrapper from './Wrapper'
import TitleBlock from './TitleBlock'
import { getContentByLocale } from '../utils/utils'
import ReviewItem from './ReviewItem'

const Root = styled.div`
`

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0 30px 0;
`

const AggregateRating = styled.p`
  color: #424242;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  font-size: 15px;
  line-height: 1.58;
`

const Item = styled.div`
  flex: 1 0 50%;
  margin: 0 40px 0 0;
  max-width: calc(50% - 20px);
  &:nth-child(2n) {
    margin: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 1 0 100%;
    max-width: 100%;
  }
`

const renderFormula = (f, p) => {
  const { averageRating, reviewCount } = p
  // eslint-disable-next-line no-eval
  return eval(`\`${f}\``)
}

const ReviewMainPage = () => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const data = useStaticQuery(query)
  const dataByLocale = getContentByLocale(data.allPrismicBlockReviews.edges, lang.locale)
  const { title, reviews } = dataByLocale[0].node.data
  const reviewCount = reviews.length
  let averageRating = 0
  // eslint-disable-next-line no-return-assign
  reviews.map(v => (averageRating += parseFloat(v.rating)))
  averageRating /= parseFloat(reviewCount)
  averageRating = averageRating.toFixed(1)
  const aggregateRatingContent = renderFormula(i18n.reviewsRated, {
    averageRating,
    reviewCount,
  })
  return (
    <Root>
      <Wrapper>
        <div vocab="http://schema.org/" typeof="Organization">
          <TitleBlock>
            {title.text} <span property="name">eSIM.Ninja</span>
          </TitleBlock>
          <AggregateRating
            property="aggregateRating"
            typeof="AggregateRating"
            dangerouslySetInnerHTML={{ __html: aggregateRatingContent }}
          />
          <Items>
            {reviews.map(v => (
              <Item>
                <ReviewItem author={v.author} rating={v.rating} dateReviewed={v.date_reviewed} review={v.review.text} />
              </Item>
            ))}
          </Items>
        </div>
      </Wrapper>
    </Root>
  )
}

const query = graphql`
  query BlockReviews {
    allPrismicBlockReviews {
      edges {
        node {
          data {
            title {
              text
            }
            reviews {
              author
              date_reviewed
              rating
              review {
                text
              }
            }
          }
          lang
        }
      }
    }
  }
`

export default ReviewMainPage
