import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import widgetPackage from '@argh/esim-widget/package'
import { Widget, Wrapper } from '../components'
import { LocaleContext } from '../components/Layout'
import SEO from '../components/SEO'
import Header from '../components/Header'
import HowWorks from '../components/HowWorks'
import WhyBlock from '../components/WhyBlock'
import SupportedDevicesBlock from '../components/SupportedDevicesBlock'
import CountryBlock from '../components/CountryBlock'
import BlogBlock from '../components/BlogBlock'
import ReviewMainPage from '../components/ReviewMainPage'
import PostOperatorBlock from '../components/PostOperatorBlock'
import FaqBlock from '../components/FaqBlock'
import camo from './camo.svg'

console.log(`eSIM widget version ${widgetPackage.version}`)

const Hero = styled.div`
  background-color: ${props => props.theme.colors.mainColorDark};
  background-image: url(${camo});
  background-size: 110vw;
  background-position: 98vw 0;
  padding-bottom: 20px;
  h1 {
    color: ${props => props.theme.colors.fontLight};
    text-align: center;
    margin: 40px auto 0 auto;
    max-width: 26em;
    font-size: 36px;
    font-weight: 800;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
     font-size: 30px;
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
     font-size: 20px;
    }
    span {
      color ${props => props.theme.colors.contrastColor}
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
     font-size: 18px;
    }
  }
`

const SubTitle = styled.strong`
  margin: 12px 0 0 0;
  display: block;
  color: ${props => props.theme.colors.fontLight};
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  line-height: 23px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 19px;
  }
`

const WidgetContiner = styled.div`
  max-width: 900px;
  margin: 0 auto;
`

const Index = ({ pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const data = useStaticQuery(query)
  const { sub_title, title, h1, description, keywords } = data.allPrismicCtaHead.edges.filter(node => node.node.lang === lang.locale)[0].node.data
  return (
    <>
      <SEO title={title} pathname={location.pathname} locale={locale} desc={description} keywords={keywords} />
      <Hero>
        <Wrapper>
          <Header location={location} />
          <h1 dangerouslySetInnerHTML={{ __html: h1 }} />
          {/* eslint-disable-next-line camelcase */}
          <SubTitle>{sub_title}</SubTitle>
          <WidgetContiner>
            <Widget filters darkTheme language={lang.locale} />
          </WidgetContiner>
        </Wrapper>
      </Hero>
      <HowWorks />
      <WhyBlock />
      <SupportedDevicesBlock />
      <CountryBlock />
      <BlogBlock />
      <PostOperatorBlock />
      <FaqBlock />
      <ReviewMainPage />
    </>
  )
}

Index.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

const query = graphql`
  query CtaHeadQuery {
    allPrismicCtaHead {
      edges {
        node {
          data {
            h1
            sub_title
            title
            description
            keywords
          }
          lang
        }
      }
    }
  }
`

export default Index
