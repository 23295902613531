import React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { LocaleContext } from './Layout'
import Wrapper from './Wrapper'
import TitleBlock from './TitleBlock'
import FaqQuestion from './FaqQuestion'
import { getContentByLocale } from '../utils/utils'
import TextBlock from './TextBlock'

const Root = styled.div`
  padding: 70px 0 40px 0;
`
const Faq = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0 30px 0;
`

const FaqBlock = () => {
  const lang = React.useContext(LocaleContext)
  const { faqQuery, block } = useStaticQuery(query)
  const blockByLocale = getContentByLocale(block.edges, lang.locale)
  const { heading, text, link } = blockByLocale[0].node.data
  const faqByLocale = getContentByLocale(faqQuery.edges, lang.locale)
  // eslint-disable-next-line camelcase
  const { faq_group } = faqByLocale[0].node.data
  return (
    <Root>
      <Wrapper id={link}>
        <TitleBlock>{heading.text}</TitleBlock>
        <TextBlock dangerouslySetInnerHTML={{ __html: text.html }} />
        <Faq itemScope itemType="https://schema.org/FAQPage">
          {faq_group.map(faq => (
            <FaqQuestion question={faq.question.text} answer={faq.answer.html} />
          ))}
        </Faq>
      </Wrapper>
    </Root>
  )
}

const query = graphql`
  query blockFaq {
    block: allPrismicBlockFaq {
      edges {
        node {
          data {
            heading {
              text
            }
            link
            text {
              html
            }
          }
          lang
        }
      }
    }
    faqQuery: allPrismicFaq {
      edges {
        node {
          lang
          data {
            faq_group {
              answer {
                html
              }
              question {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default FaqBlock
