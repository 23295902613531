import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { LocaleContext } from './Layout'
import Wrapper from './Wrapper'
import camo from '../pages/camo.svg'
import { IconCar, IconBox, IconCard, IconClock } from './Icons'
import {getContentByLocale} from "../utils/utils";

const Root = styled.div`
  background-color: ${props => props.theme.colors.mainColorDark};
  background-image: url(${camo});
  background-size: cover;
  background-position: -25vw;
  h2 {
    color: ${props => props.theme.colors.fontLight};
    font-size: 36px;
    font-weight: 800;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      font-size: 30px;
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 20px;
    }
  }
  ul {
    margin: 0;
    list-style: none;
    position: relative;
    z-index: 10;
    li {
      color: ${props => props.theme.colors.fontLight};
      font-size: 20px;
      font-weight: 500;
      &:before {
        content: '-';
        display: inline-block;
        margin: 0 10px 0 0;
      }
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        font-size: 20px;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 14px;
      }
    }
  }
`

const RootPadding = styled.div`
  padding: 210px 0;
  position: relative;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 50px 0 210px 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 50px 0 160px 0;
  }
`

const CarIco = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  width: 306px;
  height: auto;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 100px;
    right: 0;
    width: 250px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 180px;
    top: -50px;
    right: -40px;
  }
`

const BoxIco = styled.div`
  position: absolute;
  top: 250px;
  right: 330px;
  width: 179px;
  height: auto;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: auto;
    bottom: 30px;
    left: 30px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 120px;
  }
`

const CardIco = styled.div`
  position: absolute;
  top: 310px;
  right: 80px;
  width: 144px;
  height: auto;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100px;
    bottom: 100px;
    top: auto;
    right: 5%;
  }
`

const ClockIco = styled.div`
  position: absolute;
  bottom: 60px;
  right: 380px;
  width: 104px;
  height: auto;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    bottom: auto;
    right: auto;
    left: 350px;
    top: 50px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 80px;
    left: 70%;
    top: 80px;
  }
`

const WhyBlock = () => {
  const lang = React.useContext(LocaleContext)
  const data = useStaticQuery(query)
  const dataByLocale = getContentByLocale(data.allPrismicBlockWhyUse.edges, lang.locale)
  const { steps, heading } = dataByLocale[0].node.data
  return (
    <Root>
      <Wrapper>
        <RootPadding>
          <h2>{heading}</h2>
          <ul>
            {steps.map(v => (
              <li>{v.step}</li>
            ))}
          </ul>
          <CarIco>
            <IconCar />
          </CarIco>
          <BoxIco>
            <IconBox />
          </BoxIco>
          <CardIco>
            <IconCard />
          </CardIco>
          <ClockIco>
            <IconClock />
          </ClockIco>
        </RootPadding>
      </Wrapper>
    </Root>
  )
}

const query = graphql`
  query BlockWhyUseQuery {
    allPrismicBlockWhyUse {
      edges {
        node {
          data {
            steps {
              step
            }
            heading
          }
          lang
        }
      }
    }
  }
`

export default WhyBlock
