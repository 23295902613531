import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import LocalizedLink from './LocalizedLink'
import { LocaleContext } from './Layout'
import Wrapper from './Wrapper'
import TitleBlock from './TitleBlock'
import { localizedSlug } from '../utils/gatsby-node-helpers'
import { getContentByLocale } from '../utils/utils'
import TextBlock from './TextBlock'

const Root = styled.div`
  padding: 70px 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 50px 0;
    text-align: center;
  }
`
const Posts = styled.ul`
  list-style: none;
  margin: 0;
  text-align: left;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
  li {
    padding: 10px 0 0 0;
    max-width: 23%;
    flex: 1 0 25%;
    margin: 0 2.6% 0 0;
    color: ${props => props.theme.colors.fontDark};
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    :nth-child(4n) {
      margin: 0;
    }
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      flex: 1 0 50%;
      max-width: 48%;
      width: auto;
      margin: 0 4% 0 0;
      :nth-child(2n) {
        margin: 0;
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      max-width: none;
      width: auto;
      margin: 0;
    }
  }
`

const PostList = ({ node }) => (
  <li key={node.uid}>
    <LocalizedLink
      to={localizedSlug({
        uid: node.uid,
        parent: '/blog',
      })}>
      {node.data.title}
    </LocalizedLink>
  </li>
)

PostList.propTypes = {
  node: PropTypes.array.isRequired,
}

const BlogBlock = () => {
  const lang = React.useContext(LocaleContext)
  const { block, post } = useStaticQuery(query)
  const postByLocale = getContentByLocale(post.edges, lang.locale)
  const blockByLocale = getContentByLocale(block.edges, lang.locale)
  const { heading, text, link } = blockByLocale[0].node.data
  return (
    <Root>
      <Wrapper id={link}>
        <TitleBlock>{heading.text}</TitleBlock>
        <TextBlock dangerouslySetInnerHTML={{ __html: text.html }} />
        <Posts>
          {postByLocale.map(v => (
            <PostList key={v.node.uid} {...v} />
          ))}
        </Posts>
      </Wrapper>
    </Root>
  )
}

const query = graphql`
  query BlogPostsQuery {
    block: allPrismicBlockBlog {
      edges {
        node {
          data {
            heading {
              text
            }
            link
            text {
              html
            }
          }
          lang
        }
      }
    }
    post: allPrismicBlog {
      edges {
        node {
          id
          lang
          uid
          data {
            title
          }
        }
      }
    }
  }
`

export default BlogBlock
