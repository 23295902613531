import React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { LocaleContext } from './Layout'
import Wrapper from './Wrapper'
import TitleBlock from './TitleBlock'
import TextBlock from './TextBlock'
import { getContentByLocale } from '../utils/utils'

const Root = styled.div`
  background: ${props => props.theme.colors.secondColor};
  padding: 70px 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 50px 0;
  }
`

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
    margin: 0 !important;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 33.3%;
  padding: 0 10px 0 0;
  justify-content: center;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex: 1 0 50%;
    margin: 40px 0 0 0;
    max-width: 50%;
    justify-content: left !important;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 0;
    max-width: none;
  }
  span {
    display: inline-block;
    font-size: 60px;
    font-weight: 800;
    color: ${props => props.theme.colors.mainColorLight};
    margin: 0 20px 0 0;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 30px;
    }
  }
  p {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0;
    max-width: 300px;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      padding: 0 20px 0 0;
      max-width: none !important;
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 15px;
    }
  }
`

const HowWorks = () => {
  const lang = React.useContext(LocaleContext)
  const data = useStaticQuery(query)
  const dataByLocale = getContentByLocale(data.allPrismicBlockHowWork.edges, lang.locale)
  const { steps, text, heading } = dataByLocale[0].node.data
  return (
    <Root>
      <Wrapper>
        <TitleBlock>{heading}</TitleBlock>
        <TextBlock dangerouslySetInnerHTML={{ __html: text.html }} />
        <Items>
          <Item>
            <span>1.</span>
            <p>{steps[0].step}</p>
          </Item>
          <Item>
            <span>2.</span>
            <p>{steps[1].step}</p>
          </Item>
          <Item style={{ flex: 'none' }}>
            <span>3.</span>
            <p style={{ maxWidth: '200px' }}>{steps[2].step}</p>
          </Item>
          <Item style={{ justifyContent: 'center' }}>
            <span>4.</span>
            <p style={{ maxWidth: '400px' }}>{steps[3].step}</p>
          </Item>
        </Items>
      </Wrapper>
    </Root>
  )
}

const query = graphql`
  query BlockHowWorkQuery {
    allPrismicBlockHowWork {
      edges {
        node {
          data {
            steps {
              step
            }
            text {
              html
            }
            heading
          }
          lang
        }
      }
    }
  }
`

export default HowWorks
